<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12" md="6" v-if="!isLoading">
        <base-pie-chart-card
          color="primary"
          :data="chartData"
          :options="chartOptions"
        ></base-pie-chart-card>
      </v-col>

      <v-col cols="12" md="6" class="d-flex">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-3 font-weight-light">Product Out of stock</div>
          </template>
          <v-card-text>
            <v-data-table :headers="headers" :items="productsOutStock" />
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as d3 from "d3-scale-chromatic";
import { mapGetters } from "vuex";
export default {
  name: "DashboardDashboard",

  async mounted() {
    try {
      await this.$store.dispatch("dash/getDashInfo");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  computed: {
    ...mapGetters({
      orderStatusInfo: "dash/getOrderStatusInfo",
      productsOutStock: "dash/getProductsOutStock",
      isLoading: "dash/isLoading",
    }),
    chartData: function () {
      const colorScale = d3.interpolateRainbow;
      const colorRangeInfo = {
        colorStart: 0.5,
        colorEnd: 0.95,
        useEndAsStart: false,
      };
      var COLORS = this.interpolateColors(
        this.orderStatusInfo.length,
        colorScale,
        colorRangeInfo
      );
      return {
        title: "Order Statuses",
        subtitle: "Order Statuses sub",
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: this.orderStatusInfo.map((item) => {
          return item.name;
        }),
        datasets: [
          {
            label: "Data One",
            backgroundColor: COLORS,
            data: this.orderStatusInfo.map((item) => {
              return item.count;
            }),
          },
        ],
      };
    },
  },

  data() {
    return {
      orderStatus: [
        {
          name: "returned",
          count: 4,
        },
        {
          name: "archived",
          count: 284,
        },
        {
          name: "shipped",
          count: 28,
        },
        {
          name: "pending",
          count: 24,
        },
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },

      headers: [
        {
          sortable: false,
          text: "Name",
          value: "name",
          align: "left",
          width: "60%",
        },
        {
          sortable: false,
          text: "SKU",
          value: "sku",
          align: "center",
          width: "40%",
        },
      ],
    };
  },

  methods: {
    calculatePoint: function (i, intervalSize, colorRangeInfo) {
      var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
      return useEndAsStart
        ? colorEnd - i * intervalSize
        : colorStart + i * intervalSize;
    },

    interpolateColors: function (dataLength, colorScale, colorRangeInfo) {
      var { colorStart, colorEnd } = colorRangeInfo;
      var colorRange = colorEnd - colorStart;
      var intervalSize = colorRange / dataLength;
      var i, colorPoint;
      var colorArray = [];

      for (i = 0; i < dataLength; i++) {
        colorPoint = this.calculatePoint(i, intervalSize, colorRangeInfo);
        colorArray.push(colorScale(colorPoint));
      }

      return colorArray;
    },
  },
};
</script>
